import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonNote,
  IonRow,
  IonSpinner,
  IonToolbar,
  useIonActionSheet,
  useIonRouter,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createExpense,
  updateExpense,
  selectCurrentGroup,
} from "../redux/reducer/app-slice";
import getFormattedAmount from "../utils/get-formatted-amount";

const CreateExpense = ({ match = { params: { expenseId: undefined } } }) => {
  const [title, setTitle] = useState("");
  const [from, setFrom] = useState(null);
  const [amountInCents, setAmountInCents] = useState(null);
  const [loading, setLoading] = useState(false);
  /**
   * receiver
   * {
   *  participant,
   *  share: 0
   * }
   */
  const [receivers, setReceivers] = useState([]);

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const router = useIonRouter();

  const [presentToast] = useIonToast();
  const [present] = useIonActionSheet();

  const currentGroup = useSelector((state) => selectCurrentGroup(state));

  const dispatch = useDispatch();

  const amountInput = useRef(null);
  const titleInput = useRef(null);

  useEffect(() => {
    if (match.params.expenseId) {
      setLoading(true);
      const currentExpense = currentGroup.expenses.find((expense) => {
        console.log(expense);
        console.log(match.params.expenseId);
        return parseInt(expense.id) === parseInt(match.params.expenseId);
      });
      setTitle(currentExpense.name);
      setAmountInCents(parseInt(`${currentExpense.amount_in_decimals}`));
      setFrom(
        currentGroup.participants.find(
          (participant) =>
            parseInt(participant.id) === parseInt(currentExpense.payer)
        )
      );
      setReceivers(
        currentExpense.receivers.map((receiver) => ({
          id: receiver.participant_id,
          name: currentGroup.participants.find(
            (participant) =>
              parseInt(participant.id) === parseInt(receiver.participant_id)
          ).name,
          share: parseInt(receiver.absolute_share_in_decimals),
        }))
      );
      setLoading(false);
    }
  }, [match.params.expenseId]);

  useIonViewDidEnter(() => {
    titleInput.current?.setFocus();
  });

  const remainder = () => {
    const reduction = receivers.reduce((acc, current) => {
      return acc + current.share;
    }, 0);

    console.log("remainder", amountInCents - reduction);
    return amountInCents - reduction;
  };

  const handleTitleInput = (event) => {
    setTitle(event.target.value);
  };

  const handleSubmit = (event) => {
    if (title === "") {
      showToast("Gib einen Titel ein.");
      return;
    }

    if (!amountInCents) {
      showToast("Gib einen Betrag ein.");
      return;
    }

    if (!from) {
      showToast("Wähle aus wer bezahlt hat.");
      return;
    }

    setIsSubmitModalOpen(true);

    let participantReceivers = [];
    if (receivers.length === 0) {
      participantReceivers = currentGroup.participants.map((participant) => {
        return {
          ...participant,
          share: Math.round(amountInCents / currentGroup.participants.length),
        };
      });
    }

    console.log(receivers);

    if (match.params.expenseId) {
      dispatch(
        updateExpense(
          match.params.expenseId,
          {
            title,
            amount: amountInCents,
            from,
            receivers:
              receivers.length === 0 ? participantReceivers : receivers,
          },
          () => {
            router.push("/");
            setIsSubmitModalOpen(false);
          },
          (errorMessage) => {
            showToast(errorMessage);
          }
        )
      );
    } else {
      dispatch(
        createExpense(
          {
            title,
            amount: amountInCents,
            from,
            receivers:
              receivers.length === 0 ? participantReceivers : receivers,
          },
          () => {
            router.push("/");
            setIsSubmitModalOpen(false);
          },
          (errorMessage) => {
            showToast(errorMessage);
          }
        )
      );
    }

    event.preventDefault();
  };

  const selectFrom = () => {
    present({
      buttons: currentGroup.participants.map((participant) => {
        return {
          text: participant.name,
          data: {
            action: participant.id,
          },
          handler: () => {
            setFrom(participant);
          },
        };
      }),
    });
  };

  const formatMoneyInput = (value) => {
    if (isNaN(value) || value < 0) {
      return;
    }
    const number = parseFloat(value);
    const numeric = number.toFixed(2);
    return numeric;
  };

  const removeFromReceiverList = (participantId) => {
    const newReceiverCount = receivers.length - 1;
    setReceivers(
      receivers
        .filter((receiver) => receiver.id !== participantId)
        .map((receiver) => ({
          ...receiver,
          share: Math.round(amountInCents / newReceiverCount),
        }))
    );
  };

  const addToReceiverList = (participant) => {
    const newReceiverCount = receivers.length + 1;
    console.log(newReceiverCount);

    console.log(participant);
    setReceivers([
      ...receivers.map((receiver) => ({
        ...receiver,
        share: Math.round(amountInCents / newReceiverCount),
      })),
      {
        ...participant,
        share: Math.round(amountInCents / newReceiverCount),
      },
    ]);
  };

  /**
   *
   * @param {int} newAmount
   * @param {*} receiver
   * @returns
   */
  const handleShareOnBlur = (newAmount, receiver) => {
    const newAmountInt = newAmount;
    setReceivers(
      receivers.map((oldReceiver) => {
        return {
          ...oldReceiver,
          share:
            receiver.id === oldReceiver.id ? newAmountInt : oldReceiver.share,
        };
      })
    );
    /*  const newAmountInt = newAmount;
    if (newAmountInt >= amountInCents) {
      setReceivers(
        receivers.map((oldReceiver) => {
          return {
            ...oldReceiver,
            share: receiver.id === oldReceiver.id ? newAmountInt : 0,
          };
        })
      );
      return;
    }

    const receiverIndex = receivers.findIndex(
      (oldReceiver) => oldReceiver.id === receiver.id
    );

    const isLastIndex = receiverIndex === receivers.length - 1;
    const newReceivers = [...receivers];

    newReceivers[receiverIndex].share = newAmountInt;

    let total = 0;

    if (isLastIndex) {
      total = newReceivers.reduceRight((acc, current, index) => {
        if (index === 0) {
          return acc;
        } else {
          return acc + current.share;
        }
      }, 0);
      newReceivers[0].share = amountInCents - total;
    } else {
      total = newReceivers.reduce((acc, current, index, allReceivers) => {
        if (index === allReceivers.length - 1) {
          return acc;
        } else {
          return acc + current.share;
        }
      }, 0);
      newReceivers[newReceivers.length - 1].share = amountInCents - total;
    }

    const interimTotal = newReceivers.reduce((acc, current) => {
      return acc + current.share;
    }, 0);

    console.log("interimTotal", interimTotal);

    if (interimTotal > amountInCents) {
      setReceivers(
        receivers.map((oldReceiver) => {
          return {
            ...oldReceiver,
            share: receiver.id === oldReceiver.id ? newAmountInt : 0,
          };
        })
      );
      return;
    }

    console.log("total", total.toFixed(2));

    setReceivers(newReceivers);

    /* const newReceiverCount = receivers.length - 1;
    setReceivers(
      receivers.map((oldReceiver) => {
        return {
          ...oldReceiver,
          share:
            receiver.id === oldReceiver.id
              ? newAmount
              : formatMoneyInput((amount - newAmount) / newReceiverCount),
        };
      })
    ); */
  };

  const getPersonCount = () => {
    const personCount = receivers.length || currentGroup.participants.length;
    return `${personCount} Person${personCount > 1 ? "en" : ""}`;
  };

  const showToast = (message) => {
    presentToast({
      message,
      duration: 1500,
      position: "bottom",
    });
  };

  const invalidReceiverList = () => {
    return (
      remainder() >= receivers.length || remainder() + receivers.length < 0
    );
  };

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={handleSubmit}>SICHERN</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar color="primary">
          <IonInput
            autofocus={true}
            ref={titleInput}
            type="text"
            placeholder="Titel"
            className="ion-padding"
            value={title}
            onIonInput={(event) => handleTitleInput(event)}
            color={"light"}
          ></IonInput>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        {!loading ? (
          <form onSubmit={handleSubmit}>
            <IonList inset={true}>
              <IonItem onClick={() => amountInput.current?.setFocus()} button>
                <IonLabel slot="start">Beitrag</IonLabel>
                <IonInput
                  ref={amountInput}
                  type="number"
                  aria-label="Beitrag"
                  placeholder={"0,00"}
                  slot="end"
                  value={formatMoneyInput(amountInCents / 100)}
                  inputMode="numeric"
                  className="ion-text-right"
                  onIonBlur={(e) => {
                    const value = parseInt(e.target.value * 100, 10);
                    setAmountInCents(value);
                  }}
                >
                  <IonLabel slot="end">€</IonLabel>
                </IonInput>
              </IonItem>
            </IonList>
            <IonList inset={true}>
              <IonItem onClick={() => selectFrom()} button>
                <IonLabel>Von</IonLabel>
                <IonLabel slot="end">{from?.name}</IonLabel>
              </IonItem>
              <IonItem id="open-modal" button>
                <IonLabel>Für</IonLabel>
                <IonLabel slot="end">{getPersonCount()}</IonLabel>
              </IonItem>
            </IonList>
          </form>
        ) : (
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonCol size="1">
                <IonSpinner />
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
      <IonModal
        id="example-modal"
        className="custom-modal"
        trigger="open-modal"
        canDismiss={() => {
          return new Promise((resolve, reject) => {
            if (invalidReceiverList()) {
              showToast("Zu viel Restbetrag");
              resolve(false);
            } else {
              resolve(true);
            }
          });
        }}
      >
        <IonGrid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {receivers.length === 0 ? (
            <IonRow>
              <IonCol size="12" className="ion-padding ion-text-center">
                <IonLabel color={"medium"}>Für jeden</IonLabel>
              </IonCol>
              <IonCol size="12" className="ion-padding  ion-text-center">
                <IonLabel>
                  {getFormattedAmount(
                    amountInCents / currentGroup.participants.length / 100
                  )}
                  <IonLabel slot="end">€</IonLabel>
                </IonLabel>
              </IonCol>
            </IonRow>
          ) : (
            <>
              <IonRow style={{ width: "100%" }}>
                <IonCol size="12">
                  <IonList>
                    <IonListHeader>Anteilig</IonListHeader>
                    {receivers.map((receiver) => (
                      <IonItem key={receiver.id}>
                        <IonLabel slot="start">{receiver.name}</IonLabel>
                        <IonInput
                          type="number"
                          aria-label={receiver.name}
                          slot="end"
                          inputMode="numeric"
                          className="ion-text-right"
                          value={formatMoneyInput(receiver.share / 100)}
                          onIonBlur={(event) => {
                            if (event.target.value) {
                              const value = parseInt(
                                event.target.value * 100,
                                10
                              );

                              if (value === receiver.share) {
                                return;
                              }
                              if (value > amountInCents) {
                                handleShareOnBlur(amountInCents, receiver);
                              } else {
                                handleShareOnBlur(value, receiver);
                              }
                            }
                          }}
                        >
                          <IonLabel slot="end">€</IonLabel>
                        </IonInput>
                      </IonItem>
                    ))}
                    <IonItem>
                      <IonNote
                        color={invalidReceiverList() ? "danger" : ""}
                        slot="start"
                      >
                        Rest:
                      </IonNote>
                      <IonNote
                        slot="end"
                        color={invalidReceiverList() ? "danger" : ""}
                      >
                        {getFormattedAmount(remainder() / 100)}
                      </IonNote>
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </>
          )}

          <IonRow>
            <IonCol>
              {currentGroup.participants.map((participant) => {
                const isReceiver = receivers.some(
                  (receiver) => receiver.id === participant.id
                );
                return (
                  <IonLabel key={participant.id}>
                    <IonChip
                      color={isReceiver ? "primary" : undefined}
                      outline={!isReceiver}
                      onClick={() =>
                        isReceiver
                          ? removeFromReceiverList(participant.id)
                          : addToReceiverList(participant)
                      }
                    >
                      <IonAvatar>
                        <img
                          alt="Silhouette of a person's head"
                          src="https://ionicframework.com/docs/img/demos/avatar.svg"
                        />
                      </IonAvatar>
                      <IonLabel>{participant.name}</IonLabel>
                    </IonChip>
                  </IonLabel>
                );
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonModal>
      <IonModal isOpen={isSubmitModalOpen}>
        <IonContent color={"primary"}>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IonSpinner color={"light"} />
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default CreateExpense;
